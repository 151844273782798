<script>
	export default {
		name: 'inline-svg',
		functional: true,
		props: {
			id: {
				type: String,
				default: ''
			},
			color: {
				type: String,
				default: ''
			}
		},
		render(createElement, context) {
			//copy svg
			let newId = `svg-${context.props.id}`
			let _originalSVG = document.getElementById(`svg-${context.props.id}`)

			if (!_originalSVG) {
				console.error('SVG not found: ', context.props.id)
				return null
			}

			if (_originalSVG.children.length !== 1) {
				return null
			}

			_originalSVG = _originalSVG.children[0]

			let attrs = {}

			Object.keys(_originalSVG.attributes).forEach(attrKey => {
				let attr = _originalSVG.attributes[attrKey]
				attrs[attr.name] = attr.value
			})


			return createElement(
				'svg',
				{
					class: `text-${context.props.color} copy-${newId}`,
					attrs: attrs,
					domProps: {
						innerHTML: _originalSVG.innerHTML
					}
				}
			)
		}
	}
</script>
