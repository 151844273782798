<template>
	<router-link @click.prevent="click" tag="a" v-if="!external && url" :to="url" class="underline-link "
				 :class="`text-${color}`">
		<span v-if="icon && iconPosition === 'left'">
			<sprite :id="icon"></sprite>&nbsp;&nbsp;&nbsp;
		</span>
		<slot></slot>
		<span v-if="icon && iconPosition === 'right'">
			&nbsp;&nbsp;&nbsp;<sprite :id="icon"></sprite>
		</span>
		<span class="underline-link--line">
			<sprite id="line-horizontal-normal" :color="color"></sprite>
		</span>
	</router-link>

	<a @click="click" v-else-if="external && url" class="underline-link" target="_blank" :href="url"
	   :class="`text-${color}`">
		<span v-if="icon && iconPosition === 'left'">
			<sprite :id="icon"></sprite>&nbsp;&nbsp;&nbsp;
		</span>
		<slot></slot>
		<span v-if="icon && iconPosition === 'right'">
			&nbsp;&nbsp;&nbsp;<sprite :id="icon"></sprite>
		</span>
		<span class="underline-link--line">
			<sprite id="line-horizontal-normal"></sprite>
		</span>
	</a>
	<a @click.prevent="click" v-else class="underline-link" :class="`text-${color}`">
		<span v-if="icon && iconPosition === 'left'">
			<sprite :id="icon"></sprite>&nbsp;&nbsp;&nbsp;
		</span>
		<slot></slot>
		<span v-if="icon && iconPosition === 'right'">
			&nbsp;&nbsp;&nbsp;<sprite :id="icon"></sprite>
		</span>
		<span class="underline-link--line">
			<sprite id="line-horizontal-normal" :color="color"></sprite>
		</span>
	</a>
</template>

<script>
	import Sprite from 'shared/components/utils/Sprite'

	export default {
		name: 'underline-link',
		components: {Sprite},
		props: {
			color: {
				type: String,
				default: 'black'
			},
			url: {
				type: String,
				default: null
			},
			external: {
				type: Boolean,
				default: false
			},
			icon: {
				type: String,
				default: ''
			},
			iconPosition: {
				type: String,
				default: 'right'
			},
			stopClick: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			click() {
				if (this.stopClick) return
				this.$emit('click')
			}
		}
	}
</script>
