<template>
	<ul class="block_list">
		<li v-for="(item, idx) in items" :key="item.text">
			<round-btn size="medium" color="blue" v-if="item.showIcon">
				<span class="h3">{{idx+1}}</span>
			</round-btn>
			<h3 v-html="item.text"></h3>
		</li>
	</ul>
</template>

<script>
	import BgBrush from 'shared/components/atoms/BgBrush'
	import RoundBtn from 'shared/components/atoms/btns/RoundBtn'

	export default {
		name: 'block-list',
		components: {RoundBtn, BgBrush},
		props: {
			items: {
				type: Array,
				required: true
			}
		}
	}
</script>
