<template>
	<div key="resultQuestion" class="menu questions-header">
		<router-link
			class="link-with-brush menu-item"
			v-for="(q, index) in $store.state.question.questions"
			:key="q.id"
			:class="{
				'disabled': questionShouldBeDisabled(q.id)
			}"
			:to="questionUrlBySlug(q.slug)"
		>
			<span class="brush">
				<sprite id="brush-circle"></sprite>
			</span>
			<span class="title">{{index + 1}}</span>
		</router-link>
		<router-link
			exact
			class="link-with-brush menu-item"
			:class="{'disabled': !canAccessResult}"
			:to="resultUrl()"
		>
			<span class="brush">
				<sprite id="brush-circle"></sprite>
			</span>
			<span class="title">Portrait Général</span>
		</router-link>
	</div>
</template>

<script>
	import {isEmpty} from 'shared/utils/utils'
	import {dynamicRoutes} from 'app/config/routes'
	import Sprite from 'shared/components/utils/Sprite'

	export default {
		name: 'questions-header',
		components: {Sprite},
		computed: {
			question() {
				if (typeof this.$route.params.questionSlug === 'undefined') {
					return null
				}
				//Get question from ' URL index
				return this.$store.getters.getQuestionBySlug(this.$route.params.questionSlug)
			},
			result() {
				if (typeof this.$route.params.resultUID === 'undefined') {
					return null
				}
				//Get Result from ' URL
				return this.$store.getters.getResultByUID(this.$route.params.resultUID)
			},
			children() {
				if (!this.result) return
				return this.$store.getters.getChildrenById(this.result.childrenId)
			},
			canAccessResult() {
				if (!this.result) return
				return this.result.isComplete
			}
		},
		methods: {
			questionShouldBeDisabled(questionId) {
				if (!this.question) return false

				//the current question should not be disabled
				if (this.question.id === questionId) {
					return false
				}

				//if question is answer, it should not be disabled
				if (this.questionIsAnswer(questionId)) {
					return false
				}

				//if question is the next question, it should not be disabled
				if (this.questionIsNextNotAnswer(questionId)) {
					return false
				}
				return true
			},
			questionIsAnswer(questionId) {
				if (!this.result) return false

				let questionAnswer = this.result.questionsAnswers.find(questionAnswer => questionAnswer.questionId === questionId)
				if (!questionAnswer) return false

				return !isEmpty(questionAnswer.answerId)
			},
			questionIsNextNotAnswer(questionId) {
				let questionsNotAnswers = this.result.questionsAnswers.filter(questionAnswer => isEmpty(questionAnswer.answerId))
				let questionsInOrder = this.$store.getters.orderQuestionAnswers(questionsNotAnswers)
				//check if the next question to answer is this questionId
				if (!questionsInOrder) return false
				if (questionsInOrder.length === 0) return false


				return questionsInOrder[0].id === questionId
			},
			questionUrlBySlug(slug) {
				return dynamicRoutes.resultQuestions(this.children, this.result, slug)
			},
			resultUrl() {
				return dynamicRoutes.result(this.children, this.result)
			}
		}
	}
</script>
