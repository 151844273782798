<template>
	<span ref="brusheffect" class="brush-container">
		<span class="title">
			{{title}}
		</span>
		<span class="brush" ref="brush" :class="brushClass">
			<inline-svg id="brush-effect" color="green"></inline-svg>
		</span>
		<span ref="fakeTitle" class="fake">
			{{titleWithoutDash}}
		</span>
	</span>
</template>

<script>
	import InlineSvg from 'shared/components/utils/InlineSvg'

	//may use png now, etc...
	export default {
		name: 'brush-effect',
		components: {InlineSvg},
		data: function () {
			return {
				titleWithoutDash: ''
			}
		},
		props: {
			title: {
				type: String,
				default: ''
			}
		},
		watch: {
			title: {
				immediate: true,
				handler(newTitle) {
					if (this.title.includes('-')) {
						this.titleWithoutDash = newTitle.split('-')[0]
					} else if (this.title.includes(' ')) {
						this.titleWithoutDash = newTitle.split(' ')[0]
					} else {
						this.titleWithoutDash = newTitle
					}
				}

			}
		},
		created() {
			this.onResize = this.onResize.bind(this)
			window.addEventListener('resize', this.onResize)
		},
		mounted() {
			this.$nextTick(() => {
				this.onResize()
			})
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.onResize)
		},
		methods: {
			onResize() {
				//max sure we have a minimum width
				this.$refs.brush.style.minWidth = this.$refs.fakeTitle.offsetWidth + 'px'
			}
		},
		computed: {
			brushClass() {
				if (this.title.length < 6) {
					return 'small'
				}
				return 'normal'
			}
		},
	}

</script>
