<template>
	<div class="tooltip-container">
		<div class="tooltip">
			<div :class="`bg-${color} text-${color}`" class="tooltip--content"><p>{{text}}</p></div>
		</div>
		<div class="fake-tooltip">
			<div class="tooltip--content"><p>{{text}}</p></div>
		</div>
	</div>
</template>
<script>

	export default {
		name:'tooltip',
		props: ['text', 'color'],
	}
</script>
