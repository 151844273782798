<template>
	<transition
		appear
		name="slide-fade"
	>
		<div class="modal animated fadeIn" ref="modal" @click.stop="" :class="`modal-${name} modal-type-${type}`">
			<!-- Stop propagation -->
			<div class="modal--inner-container">

				<div class="modal--header">
					<div type="button" class="btn-pink" @click.prevent="_onClose">
						<span>X</span>
					</div>
				</div>
				<div class="modal--content">
					<div class="content">
						<div class="fade-top"
							 :style="`background:linear-gradient(to bottom, #FFF4F4, rgba(255,255,255,0));`"></div>
						<div class="slot">
							<slot></slot>
						</div>
						<div class="fade-bottom "
							 :style="`background:linear-gradient(to top, #FFF4F4, rgba(255,255,255,0));`"></div>
					</div>
				</div>
				<div class="modal--fake-content">
					<slot></slot>
				</div>
				<div class="bg">
					<inline-svg :color="bgColor" id="bg-big-rectangle"></inline-svg>
				</div>

			</div>

		</div>
	</transition>
</template>


<script>
	import {isDescendant} from 'shared/utils/utils'
	import InlineSvg from 'shared/components/utils/InlineSvg'

	export default {
		name: 'modal',
		components: {InlineSvg},
		props: {
			name: {
				type: String,
				required: false,
				default: 'default'
			},
			type: {
				type: String,
				default: 'normal'
			},
			bgColor: {
				type: String,
				default: 'light-pink'
			}
		},
		mounted() {
			this.parentNode = this.$refs.modal.parentNode
			window.document.body.className += ' modal-open'
			window.document.documentElement.style.overflow = 'hidden'
			window.document.body.appendChild(this.$refs.modal)

			window.document.addEventListener('keyup', this.onKeyEscape)

			//if we dont do that, the initial open click will be trigger --__--
			setTimeout(() => {
				window.document.body.addEventListener('click', this.onBodyClick)
			}, 250)
		},
		beforeDestroy() {
			this.parentNode.appendChild(this.$refs.modal)
			window.document.body.className = window.document.body.className.replace('modal-open', '')
			window.document.documentElement.style.overflow = 'auto'
			window.document.removeEventListener('keyup', this.onKeyEscape)
			window.document.body.removeEventListener('click', this.onBodyClick)
		},
		methods: {
			onKeyEscape(e) {
				if (e.keyCode === 27) {
					this._onClose()
				}
			},
			onBodyClick(e) {
				if (!isDescendant(this.$refs.modal, e.target)) {
					this._onClose()
				}
			},
			_onClose() {
				this.$emit('close')
			}
		}
	}
</script>
