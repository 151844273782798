<template>
  <div
      ref="sidebarMenu"
      class="sidebar-menu"
      :class="{'open': isOpen}"
      @click.prevent.stop=""
  >
    <div class="container-fluid h-full">
      <div class="flex flex-col between-xs h-full">
        <div class="top">
          <div class="row my-medium" v-if="siteName === 'app'">

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

              <h5>Tableau de bord</h5>

              <ul class="big-links">
                <li>
                  <a
                      :class="{'active': siteName === 'app' && $route.fullPath === '/#enfants'}"
                      @click="menuItemClick"
                      data-site="app"
                      :href="`${ECLO_APP_URL}/#enfants`">
                    Enfants
                  </a>
                </li>
                <li>
                  <a
                      :class="{'active': siteName === 'app' && $route.fullPath === '/#profil'}"
                      @click="menuItemClick"
                      data-site="app"
                      :href="`${ECLO_APP_URL}/#profil`">
                    Profil
                  </a>
                </li>
              </ul>

            </div>
          </div>

          <div class="row my-medium">

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h5>
                Liens généraux
              </h5>
              <ul class="small-links">
                <li>
                  <a

                      :class="{'active': siteName === 'default' && $route.name === 'homepage'}"
                      @click="menuItemClick"
                      data-site="default"
                      :href="ECLO_SITE_URL">
                    Accueil
                  </a>
                </li>
                <li>
                  <a

                      :class="{'active': (siteName === 'app' && $route.name === 'create-result') || (siteName === 'information' && $route.name === 'index')}"
                      @click="menuItemClick" data-site="information,app"
                      :href="`${ECLO_APP_URL}/`">
                    Accéder à l’information
                  </a>
                </li>
                <li>
                  <a
                      :class="{'active': siteName === 'default' && $route.name === 'a-propos'}"
                      @click="menuItemClick" data-site="default"
                      :href="`${ECLO_SITE_URL}/a-propos`">
                    À&nbsp;propos
                  </a>
                </li>
                <li>
                  <a

                      :class="{'active': siteName === 'default' && $route.name === 'ressources'}"
                      @click="menuItemClick" data-site="default"
                      :href="`${ECLO_SITE_URL}/ressources`">
                    Ressources
                  </a>
                </li>
                <li v-if="siteName === 'app' && $store.state.user && $store.state.user.currentUser">
                  <a
                      :class="{'active': siteName === 'app' && $route.name === 'logout'}"
                      @click="menuItemClick" data-site="app"
                      :href="`${ECLO_APP_URL}/deconnexion`">
                    Se déconnecter
                  </a>
                </li>
                <li v-if="siteName === 'app' && $store.state.user && !$store.state.user.currentUser">
                  <a

                      :class="{'active': siteName === 'app' && $route.name === 'login'}"
                      @click="menuItemClick" data-site="app"
                      :href="`${ECLO_APP_URL}/identification`">
                    Se connecter
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="row middle-xs">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-align-left">
              <div class="flex flex-row middle-xs center-xs">
                <p class="mr-small font-size-12">Une initiative de</p>
                <a href="https://orthophoniesociale.org/" target="_blank">
                  <img src="~shared/assets/images/logos/cosq2.png" class="w-xxx-large"/>
                </a>
              </div>
            </div>
          </div>


          <div class="row end-xs">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <img src="~shared/assets/images/branchs/sidebar-footer-trees.png" class="w-full"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus, EVENTS} from 'shared/event-bus'
import {toggleClass, addClass, removeClass, isDescendant, isEmpty} from 'shared/utils/utils'

const headerAnimationS = 0.4

export default {
  name: 'sidebar-header',
  data: function () {
    return {
      isOpen: false
    }
  },
  mounted() {
    TweenLite.set(this.$refs.sidebarMenu, {xPercent: 100})
  },
  created() {
    this.toggleSidebarMenu = this.toggleSidebarMenu.bind(this)
    this.closeSidebarMenu = this.closeSidebarMenu.bind(this)
    this.openSidebarMenu = this.openSidebarMenu.bind(this)

    window.addEventListener('resize', this.closeSidebarMenu)

    EventBus.$on(EVENTS.TOGGLE_SIDEBAR_MENU, this.toggleSidebarMenu)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.closeSidebarMenu)
    EventBus.$off(EVENTS.TOGGLE_SIDEBAR_MENU, this.toggleSidebarMenu)
  },
  methods: {
    menuItemClick(e) {
      if (e) {
        //use router if its the same site
        if (e.target.dataset.site.split(',').includes(window.siteName)) {
          let route = e.target.href
          //clean route for router
          route = route.replace('http:', '')
          route = route.replace('https:', '')
          route = route.replace(ECLO_APP_URL, '')
          route = route.replace(ECLO_SITE_URL, '')
          this.$router.push(route)
        } else {
          window.location.href = e.target.href
        }

      }

      this.closeSidebarMenu()
    },
    toggleSidebarMenu() {

      if (this.isOpen) {
        this.closeSidebarMenu()
      } else {
        this.openSidebarMenu()
      }

    },
    closeSidebarMenu() {
      this.isOpen = false

      removeClass(document.documentElement, 'add-sidebar-padding')
      removeClass(document.documentElement, 'menu-open')
      TweenLite.to(this.$refs.sidebarMenu, headerAnimationS, {xPercent: 100})

      this.$emit('close')

      window.removeEventListener('click', this.closeSidebarMenu)
    },
    openSidebarMenu() {

      let hasScrollbar = window.innerWidth > document.documentElement.clientWidth
      if (hasScrollbar) {
        addClass(document.documentElement, 'add-sidebar-padding')
      }

      this.isOpen = true
      addClass(document.documentElement, 'menu-open')
      TweenLite.to(this.$refs.sidebarMenu, headerAnimationS, {xPercent: 0})

      this.$emit('open')

      this.$nextTick(() => {
        setTimeout(() => {
          window.addEventListener('click', this.closeSidebarMenu)
        }, 400)
      })
    },
  }
}
</script>
