<template>
	<div class="block_text copy " v-html="text">
	</div>
</template>

<script>
	export default {
		name: 'block-text',
		props: {
			text: {
				type: String,
				required: true
			}
		}
	}
</script>
