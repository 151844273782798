const apiActions = {
    auth: {
        getCsrfToken: '/actions/eclo-app-module/user/csrf',
        getCurrentUser: '/actions/eclo-app-module/user/me',
        registerGuestUser: '/actions/eclo-app-module/user/register-guest-user',
        finishGuest: '/actions/eclo-app-module/user/finish-guest-user',
    },
    user: {
        update: '/actions/eclo-app-module/user/update',
        getUidFromEmail: '/actions/eclo-app-module/user/get-uid-from-email'
    },
    children: {
        getAllForUser: '/actions/eclo-app-module/children/get',
        create: '/actions/eclo-app-module/children/create',
        //need to update ?
    },
    strategy: {
        getByUid: '/actions/eclo-app-module/data/get-strategy-by-uid',
    },
    result: {
        getAll: '/actions/eclo-app-module/result/get',
        create: '/actions/eclo-app-module/result/create',
        update: '/actions/eclo-app-module/result/update',
    },
    data: {
        getAll: '/actions/eclo-app-module/data/get-all',
    },
    question: {
        getAll: '/actions/eclo-app-module/question/get'
    },
    payment: {
        getAll: '/actions/eclo-app-module/payment/get',
        getAllPaymentsMethod: '/actions/eclo-app-module/payment/get-payments-method',
        addPaymentMethod: '/actions/eclo-app-module/payment/add-payment-method',
        makePayment: '/actions/eclo-app-module/payment/make-payment'
    }
}

export default apiActions
