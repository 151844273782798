<template>
	<div class="block_video">
		<p class="error my-normal font-size-16" v-if="!videoId">Veuillez entrer un vidéo ID</p>
		<div class="video--item" v-else>


			<div class="youtube-container embed-container" v-if="videoType === 'youtube'">
				<div class="no-js-player">
					<iframe :src="`https://www.youtube-nocookie.com/embed/${videoId}`" frameborder="0"
							allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen></iframe>
				</div>

				<div class="js-player">
					<div ref="plyrVideo" class="plyr-video" data-provider="youtube" data-plyr-provider="youtube"
						 :data-plyr-embed-id="videoId"></div>
				</div>
			</div>

			<div class="vimeo-container embed-container" v-else>
				<div class="no-js-player">
					<iframe
						:src="`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&muted=1&autoplay=0&background=1&responsive=1&loop=1&autopause=0`"
						frameborder="0"
						webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
				</div>

				<div class="js-player">
					<div ref="plyrVideo" class="plyr-video js-player" data-plyr-provider="vimeo"
						 :data-plyr-embed-id="videoId"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'block_video',
		props: {
			videoId: {
				type: String
			},
			videoType: {
				type: String,
				default: 'youtube'
			}
		},
		beforeDestroy() {
			if (this.player) this.player.destroy();
		},
		mounted() {
			if (!this.videoId) return
			const controls = '<div class="plyr__controls"><button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play"><svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg><svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg></button></div>'

			let video = this.$refs.plyrVideo.querySelector('video')
			let options = {
				volume: 0,
				autoplay: false,
				controls: controls
			}

			if (typeof this.$refs.plyrVideo.dataset.draggable !== 'undefined') {
				if (this.$refs.plyrVideo.dataset.draggable === 'true') {
					options['controls'] = []
				}
			}

			this.player = new Plyr(video || this.$refs.plyrVideo, options)

		}
	}
</script>
