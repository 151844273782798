<template>
	<div class="block_images_gallery slider" ref="slider">
		<div class="item" v-for="image in images" :key="image.title">
			<img class="w-full block" :src="image.url" :alt="image.title">
		</div>
	</div>
</template>

<script>
	import { tns } from "tiny-slider/src/tiny-slider"

	export default {
		name: 'block_images_gallery',
		props: {
			images: {
				type: Array
			}
		},
		beforeDestroy(){
			if (this.slider) this.slider.destroy();
		},
		mounted() {
			this.slider = tns({
				container: this.$refs.slider,
				items: 3,
				slideBy: 1,
				autoplay: false,
				nav:false,
				controlsPosition:'bottom',
				controlsText:['<svg viewBox=\'0 0 12 20\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M10.3333 1.66668L1.99992 10L10.3333 18.3333\' stroke=\'black\' stroke-width=\'2\' stroke-miterlimit=\'10\'/></svg>', '<svg viewBox=\'0 0 12 20\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M1.6665 18.3333L9.99984 9.99999L1.6665 1.66666\' stroke=\'black\' stroke-width=\'2\' stroke-miterlimit=\'10\'/></svg>'],
				responsive: {
					0: {
						items: 1
					},
					600: {
						items: 2
					},
					1000: {
						items: 3
					}
				}
			})

		}
	}
</script>
