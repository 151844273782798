import Vue from 'vue'
import {formatDate, includesAny} from 'shared/utils/utils'

Vue.filter('lower', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.toLowerCase()
})


Vue.filter('initial', function (value) {
	if (!value) return ''
	let initial = value[0]

	if (value.includes('-')) {
		initial += value[value.indexOf('-') + 1]
	}

	return initial.toUpperCase()
})

Vue.filter('formatDate', function (date) {
	return formatDate(date)
})

Vue.filter('title', function (str) {
	return str.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase() });
})

Vue.filter('replace', function (string, search, replace) {
	return string.replace(search, replace)
})


Vue.filter('formatQuestionResume', function (resume, childFirstName) {
	if (!childFirstName) return resume
	let variable = '{name}'

	//if not the variables
	if (!resume.includes(variable)) {
		return resume
	}


	//remove whitespace before and after
	childFirstName = childFirstName.trim()

	//need to be same order
	let beforeWords = ['[de]', '[le]', '[la]', '[De]', '[Le]', '[La]']
	let originalWords = ['de', 'le', 'la', 'De', 'Le', 'La']
	let replaceWords = ['d\'', 'l\'', 'l\'', 'D\'', 'L\'', 'L\'']

	let vowels = [
					'a', 'à',
					'e', 'é', 'é',
					'i', 'ï',
					'o', 'ö',
					'u', 'ü',
					'y',
					'h'
				 ]


	//simpliest, the resume dont contain a before word
	if (!includesAny(resume, beforeWords)) {
		return resume.replace(variable, childFirstName)
	}

	if (!vowels.includes(childFirstName[0].toLowerCase())) {
		//child name dont start with vowels
		//return the simpliest solution
		beforeWords.forEach((word, idx) => {
			resume = resume.replace(word, originalWords[idx])
		})

		return resume.replace(variable, childFirstName)
	}


	beforeWords.forEach((word, idx) => {
		resume = resume.replace(word, replaceWords[idx])
	})
	//remove space before the name
	let indexOfVariable = resume.indexOf(variable) - 1

	const newStringArray = resume.split('')
	newStringArray.splice(indexOfVariable, 1)
	resume = newStringArray.join('')

	return resume.replace(variable, childFirstName)

})
