<template>
	<div class="round-btn"
		 :class="`minw-${size} w-${size} minh-${size} h-${size}`"
	>
		<span class="">
			<slot></slot>
		</span>
		<span class="circle-brush z--1"
			  :class="`text-${color} h-${size}`"
			  :style="colorHEX !== '' ? `color:${colorHEX};` : ''"
		>
			<inline-svg id="brush-circle"></inline-svg>
		</span>
	</div>
</template>

<script>
	import InlineSvg from 'shared/components/utils/InlineSvg'

	export default {
		name: 'round-btn',
		components: {InlineSvg},
		props: {
			size: {
				type: String,
				default: 'large'
			},
			color: {
				type: String,
				default: 'green'
			},
			colorHEX: {
				type: String,
				default: ''
			}
		}
	}
</script>
