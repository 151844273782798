<template>
	<div class="bg-brush">
		<div class="bg">
			<inline-svg :color="color" id="bg-large"></inline-svg>
		</div>

		<div class="bg-brush--content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import InlineSvg from 'shared/components/utils/InlineSvg'

	export default {
		name: 'bg-brush',
		components: {InlineSvg},
		props: {
			color: {
				type: String,
				default: 'light-blue'
			}
		}
	}
</script>
