import Vue from 'vue'

Vue.mixin({
	data: function () {
		return {
			siteName: window.siteName,
			SITE_AD_IMAGE_URL: window.SITE_AD_IMAGE_URL || null,
			ECLO_APP_URL: window.ECLO_APP_URL,
			ECLO_SITE_URL: window.ECLO_SITE_URL
		}
	}
})
