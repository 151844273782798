<template>
	<div class="block_blockquote copy ">
		<bg-brush>
			<p v-html="blockquote"></p>
		</bg-brush>
	</div>
</template>

<script>
	import BgBrush from 'shared/components/atoms/BgBrush'
	export default {
		name: 'block-blockquote',
		components: {BgBrush},
		props: {
			blockquote: {
				type: String,
				required: true
			}
		}
	}
</script>
