import {
	TweenLite,
	CSSPlugin,
	ScrollToPlugin
} from 'gsap/all'

// Ensure modules don't get dropped by tree-shaking
const gsap = [
	TweenLite,
	CSSPlugin,
	ScrollToPlugin
]

export default gsap
