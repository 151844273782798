<script>
	import BlockSpacer from 'shared/components/builder/BlockSpacer'
	import BlockImage from 'shared/components/builder/BlockImage'
	import BlockText from 'shared/components/builder/BlockText'
	import BlockVideo from 'shared/components/builder/BlockVideo'
	import BlockImagesGallery from 'shared/components/builder/BlockImagesGallery'
	import BlockBlockQuote from 'shared/components/builder/BlockBlockQuote'
	import BlockList from 'shared/components/builder/BlockList'
	import BlockTitle from 'shared/components/builder/BlockTitle'
	import BlockLine from 'shared/components/builder/BlockLine'

	const BUILDER_COMPONENTS = {
		'block_title': BlockTitle,
		'block_text': BlockText,
		'block_video': BlockVideo,
		'block_image': BlockImage,
		'block_images_gallery': BlockImagesGallery,
		'block_spacer': BlockSpacer,
		'block_blockquote': BlockBlockQuote,
		'block_list': BlockList,
		'block_line': BlockLine
	}
	export default {
		name: 'builder-loop',
		props: {
			builder: {
				required:true
			}
		},
		render: function (createElement) {
			let builder = []

			//recusive render children
			let renderChildrens = (data) => {
				let childrens = []
				if (data.childrens.length > 0) {
					data.childrens.forEach((item, idx) => {
						let itemChilds = []
						if (item.childrens.length > 0) {
							itemChilds.push(renderChildrens(item))
						}
						childrens.push(
							createElement(
								BUILDER_COMPONENTS[item.blockType],
								{
									props: item.props
								},
								itemChilds
							)
						)

					})

				}
				return childrens
			}


			//make sure we convert if its passs by craft
			let builderProps = this.builder
			if (typeof builderProps === 'string') {
				builderProps = JSON.parse(this.builder)
			}

			//render level 1 + children
			builderProps.forEach(block => {
				builder.push(
					createElement(
						BUILDER_COMPONENTS[block.blockType],
						{
							props: block.props
						},
						renderChildrens(block)
					)
				)
			})

			//render entire dom tree
			return createElement(
				'div',
				{
					class: {
						'builder': true
					}
				},
				builder
			)
		},
	}
</script>
