<template>
	<div class="block_line">
		<inline-svg id="line"></inline-svg>
	</div>
</template>

<script>
	import Sprite from 'shared/components/utils/Sprite'
	import InlineSvg from 'shared/components/utils/InlineSvg'

	export default {
		name: 'block-line',
		components: {InlineSvg, Sprite}
	}
</script>
