//simple mapping of url

export const routes = {
	index: '/',
	result: '/bilans/:childrenSlug/:resultUID',
	createResult: '/bilans/nouveau',
	strategy:'/strategy/:strategyUID/:strategySlug',
	resultQuestions: '/bilans/:childrenSlug/:resultUID/questions/:questionSlug',
	auth:'/auth',
	login: '/identification',
	register: '/enregistrement',
	logout: '/deconnexion',
	forgotPassword: '/identification/reinitialisation',
	setPassword: '/identification/reinitialisation/nouveau',
	error:'/404'
}

export const dynamicRoutes = {
	result: (child, result) => `/bilans/${child.slug}/${result.uid}`,
	resultQuestions: (child, result, slug) => `/bilans/${child.slug}/${result.uid}/questions/${slug}`
}

