<template>
	<div class="container-fluid error-page">
		<div class="row center-xs ">
			<div class="mx-auto col-xs-8 col-sm-10 col-md-6 col-lg-6">
				<h2 class="text-align-center">Oups, cette page n'existe pas</h2>
				<div class="mx-auto my-medium col-xs-8 col-sm-8 col-md-6 col-lg-6">
					<img src="~shared/assets/images/eclo-404.png" class="block"/>
				</div>

				<div class="mx-auto text-align-center relative">
					<router-link class="btn btn-primary btn-default-brush" to="/">
						<brush-btn type="btn-large">
							Accéder à l’information
							<sprite id="arrow-right"></sprite>
						</brush-btn>
					</router-link>
				</div>

			</div>
		</div>
		<img src="~shared/assets/images/branchs/branchs-combined.png"
			 class="img-footer"/>
	</div>
</template>

<script>

	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import Sprite from 'shared/components/utils/Sprite'

	export default {
		name: 'error-page',
		components: {Sprite, BrushBtn}
	}
</script>
