<template>
	<div class="toggle">
		<label :for="`toggle-${_uid}`"></label>
		<div class="toggle-item" :class="{'checked': isChecked}">
			<input
				:id="`toggle-${_uid}`"
				:checked="isChecked"
				@change.prevent="onClick(!checked)"
				type="checkbox"
			>
			<span class="circle"></span>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'

	export default {
		name: 'toggle',
		props: ['checked'],
		data: function () {
			return {
				isChecked: this.checked
			}
		},
		watch: {
			checked: function (newValue, oldValue) {
				this.isChecked = newValue
			}
		},
		methods: {
			onClick(newValue) {

				//make sure we switch
				if (newValue === this.isChecked) {
					newValue = !newValue
				}

				this.isChecked = newValue
				this.$emit('change', newValue)
			}
		}
	}
</script>
