<template>
	<span class="brush-btn" :class="[classes, `-type-${type}`]">
		<span class="title flex flex-row flex-nowrap center-xs middle-xs">
			<slot></slot>
		</span>
		<span class="brush">
			<inline-svg :color="color" :id="type"></inline-svg>
		</span>
	</span>

</template>

<script>
	import InlineSvg from 'shared/components/utils/InlineSvg'

	export default {
		name: 'brush-btn',
		components: {InlineSvg},
		props: {
			classes: {
				type: String,
				default: ''
			},
			type: {
				type: String,
				default: 'btn-large'
			},
			color: {
				type: String,
				default: ''
			}
		}
	}
</script>
