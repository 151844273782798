//Error handling
const ERROR = 'ERROR'
const USER_ERROR = 'USER_ERROR'
const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA'
const ROUTE_CHANGED = 'ROUTE_CHANGED'

//Genral Auth handling
const GET_CURRENT_USER = 'GET_CURRENT_USER'
const LOGIN_USER = 'LOGIN_USER'
const REGISTER_USER = 'REGISTER_USER'
const REGISTER_GUEST_USER = 'REGISTER_GUEST_USER'
const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
const RESET_PASSWORD = 'RESET_PASSWORD'
const LOGOUT_USER = 'LOGOUT_USER'
const CSRF = 'CSRF'
const FINISH_GUEST = 'FINISH_GUEST'
const RESET_DATA = 'RESET_DATA'

//User Stuff
const MODIFY_USER = 'MODIFY_USER'

//Bilan handling
const GET_RESULTS = 'GET_RESULTS'
const CREATE_RESULT = 'CREATE_RESULT'
const UPDATE_RESULT = 'UPDATE_RESULT'

//Questions
const GET_QUESTIONS = 'GET_QUESTIONS'

//Children
const GET_CHILDRENS = 'GET_CHILDRENS'
const CREATE_CHILDREN = 'CREATE_CHILDREN'

//Loader
const START_LOADER = 'START_LOADER'
const STOP_LOADER = 'STOP_LOADER'
const STOP_INITIAL_LOADER = 'STOP_INITIAL_LOADER'
const STOP_ALL_LOADER = 'STOP_ALL_LOADER'

//Payment stuff
const MAKE_PAYMENT = 'MAKE_PAYMENT'
const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD'
const GET_PAYMENTS_METHOD  = 'GET_PAYMENTS_METHOD'
const GET_PAYMENTS = 'GET_PAYMENTS'


const GET_DATA = 'GET_DATA'



export default {
	STOP_ALL_LOADER,
	GET_DATA,
	STOP_INITIAL_LOADER,
	UPDATE_RESULT,
	ERROR,
	CREATE_CHILDREN,
	ROUTE_CHANGED,
	USER_ERROR,
	FETCH_INITIAL_DATA,
	CREATE_RESULT,
	GET_CURRENT_USER,
	LOGIN_USER,
	LOGOUT_USER,
	REGISTER_USER,
	REGISTER_GUEST_USER,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	CSRF,
	GET_QUESTIONS,
	FINISH_GUEST,
	GET_RESULTS,
	GET_CHILDRENS,
	RESET_DATA,
	MODIFY_USER,
	START_LOADER,
	STOP_LOADER,
	MAKE_PAYMENT,
	GET_PAYMENTS_METHOD,
	ADD_PAYMENT_METHOD,
	GET_PAYMENTS
}
