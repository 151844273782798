<template>
	<div class="block_image">
		<img class="block w-full" :src="url" :alt="title">
	</div>
</template>
<script>
	export default {
		name: 'block_image',
		props: {
			url: {
				type: String
			},
			title: {
				type: String
			}
		}
	}
</script>
