<template>
	<footer class="l-footer">

		<div class="container-fluid mt-large">
			<div class="row mb-large" v-if="siteName === 'app' || siteName === 'information'">
				<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
					<div class="mx-auto text-align-center relative">
						<a class="l-footer__back-to-site | btn btn-primary btn-default-brush" :href="ECLO_SITE_URL">
							<brush-btn type="btn-large">
								<sprite id="arrow-left"></sprite>
								Retour au site
							</brush-btn>
						</a>
						<div class="footer--line">
							<sprite id="line-horizontal-large"></sprite>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid max-width-1200">

			<div class="l-footer__illustration | row">
				<div class="col-xs-12 col-sm-10 col-md-8 col-lg-6">
					<img src="~shared/assets/images/footer-first.png" class="w-full"/>
				</div>
			</div>

			<div class="l-footer__nav | row">
				<nav class="col-xxs-12 col-xs-12 col-sm-10 col-md-8 col-lg-8 buttons-section my-medium no-gutter">
					<ul class="l-footer__nav--1 mb-medium">
						<li class="text-align-center">
							<a class="btn btn-menu-brush active w-full" :href="`${ECLO_APP_URL}/`">
								<brush-btn type="btn-medium">
									Accéder à l’information
								</brush-btn>
							</a>
						</li>
						<li class="text-align-center">
							<a class="btn btn-menu-brush w-full" :href="`${ECLO_SITE_URL}/a-propos`">
								<brush-btn type="btn-medium">
									À&nbsp;propos
								</brush-btn>
							</a>
						</li>
						<li class="text-align-center">
							<a class="btn btn-menu-brush w-full" :href="`${ECLO_SITE_URL}/ressources`">
								<brush-btn type="btn-medium">
									Ressources
								</brush-btn>
							</a>
						</li>
					</ul>
				</nav>
			</div>

			<div class="l-footer__initiative | row middle-xs center-xs my-large">
				<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-align-center">
					<div class="flex flex-row middle-xs center-xs">
						<p class="mr-small">Une initiative de</p>
						<a href="https://orthophoniesociale.org/" target="_blank">
							<img src="~shared/assets/images/logos/cosq2.png" class="w-xxx-large"/>
						</a>
					</div>
				</div>
			</div>

			<p class="l-footer__copyright text-align-center mt-normal">
				<span>Design & Développement web </span>
				<a href="https://mambomambo.ca" target="_blank" class="text-green">MamboMambo</a>
			</p>

			<div class="row">
				<div class="col-xs-12 col-sm-10 col-md-8 col-lg-6">
					<img src="~shared/assets/images/footer-last.png" class="w-full"/>
				</div>
			</div>
		</div>
	</footer>

</template>

<script>
	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import Sprite from 'shared/components/utils/Sprite'

	export default {
		name: 'vue-footer',
		components: {Sprite, BrushBtn},
	}


</script>
