<template>
	<span class="line-hori-underline">
		<slot></slot>
		<span class="text-green">
			<sprite id="line-horizontal-xlarge"></sprite>
		</span>
	</span>
</template>

<script>
	import Vue from 'vue'
	import Sprite from 'shared/components/utils/Sprite'
	const UnderlineGreenBrush = Vue.component('underline-green-brush', {
		components: {Sprite}
	})

	export default UnderlineGreenBrush
</script>
