<script>
	export default {
		name: 'block-title',
		props: {
			title: {
				type: String,
				required: true
			},
			type: {
				type: String,
				required: true
			}
		},
		functional: true,
		render(h, context) {
			return h(context.props.type, context.props.title)
		}
	}
</script>
